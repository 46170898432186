<template>
  <div class="notice-setting__wrapper">
    <p class="notice-setting__title">
      {{ $t('app.header.notice.setting') }}
    </p>
    <div class="notice-setting__list">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column class-name="type-col" prop="type" width="195" :label="$t('notice.messageType')">
          <template #default="scope">
            <div class="type-wrap">{{ scope.row.type }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="msg" :label="$t('app.header.notice.mine')">
          <template #default="scope">
            <el-checkbox disabled v-model="scope.row.msg" />
          </template>
        </el-table-column>
        <!-- <el-table-column prop="email" label="邮件">
          <template #default="scope">
            <el-checkbox :disabled="scope.row.type === '团队消息'" v-model="scope.row.email" size="large" />
          </template>
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import { t } from 'app/i18n'

const tableData = reactive([
  {
    msg: true,
    email: true,
    type: t('app.header.notice.system')
  },
  {
    msg: true,
    email: true,
    type: t('app.header.notice.team')
  }
  // {
  //   msg: false,
  //   email: false,
  //   type: '检测完成消息'
  // },
  // {
  //   msg: false,
  //   email: false,
  //   type: '订阅消息'
  // }
])
</script>
<style lang="scss" scoped>
.notice-setting__wrapper {
  padding: 20px 24px 30px;
  min-height: calc(100% - 112px);
  .notice-setting__title {
    margin: 0;
    font-size: 18px;
    color: var(--color-text-1);
    line-height: 25px;
  }
  .notice-setting__list {
    margin-top: 15px;
    height: calc(100vh - 257px);
    padding: 24px;
    background: var(--detect-config-bg);
    border-radius: 2px;
    .type-wrap {
      font-weight: 600;
    }
    :deep(.type-col) {
      background-color: var(--color-bg-3);
    }
    :deep(.el-table__row) {
      height: 48px;
    }
    :deep(.el-table__header) {
      height: 48px;
      .el-table__cell {
        background-color: var(--color-bg-3);
      }
    }
  }
}
</style>
